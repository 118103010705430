import {useEffect, useState} from "react";
import {IProject, ProjectAPI} from "api/projects";
import {
    selectCurrentProject, selectProjects,
    setCurrentProject,
    setProjects,
} from "store/slices/projectsSlice";
import {isEmpty} from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "store";

export function useProjects() {
    const dispatch = useDispatch();
    const currentProject = useSelector((state: RootState) =>
        selectCurrentProject(state)
    );
    const projects = useSelector((state: RootState) => selectProjects(state));

    useEffect(() => {
        let loading = false;
        const fetchProjects = async () => {
            try {
                loading = true;
                const response = await ProjectAPI.getProjects();
                dispatch(setProjects(response));
                if (isEmpty(currentProject)) {
                    dispatch(setCurrentProject(response[0]));
                }
            } finally {
                loading = false;
            }
        };

        if (!loading) {
            if (isEmpty(currentProject) || isEmpty(projects)) {
                fetchProjects();
            }
        }
    }, []);
}

export function useCurrentProject(): [IProject, CallableFunction] {
    const dispatch = useDispatch();
    const currentProject = useSelector((state: RootState) =>
        selectCurrentProject(state)
    );
    const updateCurrentProject = (project: IProject) =>
        dispatch(setCurrentProject(project));

    return [currentProject, updateCurrentProject];
}
