import axios from "api/index";

export interface ICompany {
  id: number;
  name: string;
  slug: string;
  active: boolean;
  deleted: boolean;
}

export interface IProject {
  id: number;
  name: string;
  slug: string;
  active: boolean;
  deleted: boolean;
  company: ICompany;
}

export interface ICompanyPayload {
  name: string;
  active: boolean;
}

export interface IProjectPayload {
  name: string;
  active: boolean;
  deleted: boolean;
  companyId: number;
}

export class ProjectAPI {
  static async getProjects(): Promise<IProject[]> {
    const response = await axios.get("/projects");
    return response.data;
  }

  static async get(projectId: number): Promise<IProject> {
    const response = await axios.get(`/projects/${projectId}`);
    return response.data;
  }

  static async update(
    projectId: number,
    payload: IProjectPayload
  ): Promise<IProject> {
    const response = await axios.put(`/projects/${projectId}`, {
      name: payload.name,
      company_id: payload.companyId,
      active: payload.active,
      deleted: payload.deleted,
    });
    return response.data;
  }

  static async create(payload: IProjectPayload): Promise<IProject> {
    const response = await axios.post("/projects", {
      name: payload.name,
      company_id: payload.companyId,
      active: payload.active,
      deleted: payload.deleted,
    });
    return response.data;
  }
}

export class CompanyAPI {
  static async getCompanies(): Promise<ICompany[]> {
    const response = await axios.get("/companies");
    return response.data;
  }

  static async createCompany(payload: ICompanyPayload): Promise<ICompany> {
    const response = await axios.post("/companies", payload);
    return response.data;
  }
}
