import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProject } from "api/projects";
import { RootState } from "store/index";

export const projectSlice = createSlice({
  name: "projects",
  initialState: {
    currentProject: {} as IProject,
    projects: [] as IProject[],
  },
  reducers: {
    setCurrentProject: (state, action: PayloadAction<IProject>) => {
      state.currentProject = action.payload;
    },
    setProjects: (state, action: PayloadAction<IProject[]>) => {
      state.projects = action.payload;
    },
  },
});

export const selectCurrentProject = (state: RootState): IProject =>
  state.projects.currentProject;
export const selectProjects = (state: RootState): IProject[] =>
  state.projects.projects;

export const { setCurrentProject, setProjects } = projectSlice.actions;
export default projectSlice.reducer;
