import * as Sentry from "@sentry/react";
import * as process from "process";


export function initSentrySdk() {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_ENVIRONMENT,
        release: process.env.REACT_APP_VERSION,
        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
            new Sentry.Feedback({
                // Additional SDK configuration goes in here, for example:
                colorScheme: "light",
            }),
            new Sentry.Replay(),
        ],

    });
}