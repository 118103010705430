import axios from "api/index";

export interface SignInResponse {
  access_token: string;
  token_type: string;
}

export class AuthAPI {
  static async login(email: string, password: string): Promise<SignInResponse> {
    const formData = new FormData();
    formData.append("username", email);
    formData.append("password", password);
    const response = await axios({
      method: "post",
      url: "/users/login",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  }
}
