import React, { ReactNode } from "react";
import LoadingScreen from "components/LoadingScreen";
import HomeIcon from "@mui/icons-material/Home";
import { Business, Cloud, Google } from "@mui/icons-material";
import ProjectContainer from "views/ProjectContainer";

const Home = React.lazy(() => import("views/Home"));
const SignIn = React.lazy(() => import("views/auth/SignIn"));
const NotFound = React.lazy(() => import("views/NotFound"));
const Projects = React.lazy(() => import("views/projects/ProjectList"));
const ProjectUpdate = React.lazy(() => import("views/projects/ProjectUpdate"));
const ProjectCreate = React.lazy(() => import("views/projects/ProjectCreate"));
const CompanyCreate = React.lazy(() => import("views/projects/CompanyCreate"));
const WeatherScenarios = React.lazy(() => import("views/weather/WeatherList"));
const WeatherEdit = React.lazy(() => import("views/weather/WeatherEdit"));
const WeatherCreate = React.lazy(() => import("views/weather/WeatherCreate"));
const DV360List = React.lazy(() => import("views/adsystem/dv360/DV360List"));
const DV360Edit = React.lazy(() => import("views/adsystem/dv360/DV360Edit"));
const DV360Create = React.lazy(
  () => import("views/adsystem/dv360/DV360Create")
);
const ConnectGoogleAdsCallback = React.lazy(
    () => import("views/auth/platform_connection/ConnectGoogleAdsCallback")
    );

export enum RoutePaths {
  HOME = "/",
  LOGIN = "/login",
  NOT_FOUND = "*",
  PROJECTS = "/projects",
  PROJECT_DETAIL = "/projects/:projectId",
  PROJECT_UPDATE = "edit",
  PROJECT_CREATE = "/projects/create-new",
  COMPANY_CREATE = "/companies/create-new",
  WEATHER_SCENARIOS = "/weather-scenarios",
  WEATHER_CREATE = "/weather-scenarios/new",
  WEATHER_EDIT = "/weather-scenarios/:scenarioId",
  DV360_LIST = "/ads/dv360",
  DV360_EDIT = "/ads/dv360/:id",
  DV360_CREATE = "/ads/dv360/new",
  CONNECT_GOOGLE_ADS = "/auth/connect/google-ads",
}

export interface IRoute {
  element: JSX.Element;
  path: RoutePaths;
  children: IRoute[];
  meta?: {
    mainMenu: boolean;
    name: string;
    icon: ReactNode;
  };
}

const Routes: IRoute[] = [
  {
    element: (
      <React.Suspense fallback={<LoadingScreen />}>
        <NotFound />
      </React.Suspense>
    ),
    path: RoutePaths.NOT_FOUND,
    children: [],
  },
  {
    element: (
      <React.Suspense fallback={<LoadingScreen />}>
        <Home />
      </React.Suspense>
    ),
    path: RoutePaths.HOME,
    children: [],
    meta: {
      mainMenu: true,
      name: "Home",
      icon: <HomeIcon />,
    },
  },
  {
    element: (
      <React.Suspense fallback={<LoadingScreen />}>
        <SignIn />
      </React.Suspense>
    ),
    path: RoutePaths.LOGIN,
    children: [],
  },
  {
    element: (
      <React.Suspense fallback={<LoadingScreen />}>
        <Projects />
      </React.Suspense>
    ),
    path: RoutePaths.PROJECTS,
    children: [],
    meta: {
      mainMenu: true,
      name: "Projects",
      icon: <Business />,
    },
  },
  {
    element: <ProjectContainer />,
    children: [
      {
        element: (
          <React.Suspense fallback={<LoadingScreen />}>
            <ProjectUpdate />
          </React.Suspense>
        ),
        path: RoutePaths.PROJECT_UPDATE,
        children: [],
      },
    ],
    path: RoutePaths.PROJECT_DETAIL,
  },
  {
    element: (
      <React.Suspense fallback={<LoadingScreen />}>
        <ProjectCreate />
      </React.Suspense>
    ),
    path: RoutePaths.PROJECT_CREATE,
    children: [],
  },
  {
    element: (
      <React.Suspense fallback={<LoadingScreen />}>
        <CompanyCreate />
      </React.Suspense>
    ),
    path: RoutePaths.COMPANY_CREATE,
    children: [],
  },
  {
    element: (
      <React.Suspense fallback={<LoadingScreen />}>
        <WeatherScenarios />
      </React.Suspense>
    ),
    path: RoutePaths.WEATHER_SCENARIOS,
    children: [],
    meta: {
      mainMenu: true,
      name: "Weather Scenarios",
      icon: <Cloud />,
    },
  },
  {
    element: (
      <React.Suspense fallback={<LoadingScreen />}>
        <WeatherEdit />
      </React.Suspense>
    ),
    path: RoutePaths.WEATHER_EDIT,
    children: [],
  },
  {
    element: (
      <React.Suspense fallback={<LoadingScreen />}>
        <WeatherCreate />
      </React.Suspense>
    ),
    path: RoutePaths.WEATHER_CREATE,
    children: [],
  },
  {
    element: (
      <React.Suspense fallback={<LoadingScreen />}>
        <DV360List />
      </React.Suspense>
    ),
    path: RoutePaths.DV360_LIST,
    children: [],
    meta: {
      mainMenu: true,
      name: "DV 360",
      icon: <Google />,
    },
  },
  {
    element: (
      <React.Suspense fallback={<LoadingScreen />}>
        <DV360Edit />
      </React.Suspense>
    ),
    path: RoutePaths.DV360_EDIT,
    children: [],
  },
  {
    element: (
      <React.Suspense fallback={<LoadingScreen />}>
        <DV360Create />
      </React.Suspense>
    ),
    path: RoutePaths.DV360_CREATE,
    children: [],
  },
  {
    element: (
        <React.Suspense fallback={<LoadingScreen />}>
          <ConnectGoogleAdsCallback />
        </React.Suspense>
    ),
    path: RoutePaths.CONNECT_GOOGLE_ADS,
    children: [],
  }
];

export default Routes;
