import React from "react";
import { useRoutes } from "react-router-dom";
import Routes from "routes";

function App() {
  const routes = useRoutes(Routes);
  return <React.Fragment>{routes}</React.Fragment>;
}

export default App;
