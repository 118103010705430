import { Outlet } from "react-router-dom";
import RequireAuth from "routes/RequireAuth";
import AppLayout from "layouts/AppLayout";

const ProjectContainer = () => {
  return (
    <RequireAuth>
      <AppLayout>
        <Outlet />
      </AppLayout>
    </RequireAuth>
  );
};

export default ProjectContainer;
