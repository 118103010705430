import BaseLayout from "./BaseLayout";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Routes, { RoutePaths } from "routes";
import { AccountCircle, Logout } from "@mui/icons-material";
import React from "react";
import AuthService from "../services/auth/AuthService";
import ProjectSelector from "../components/ProjectSelector";

const AppLayout = (props: React.PropsWithChildren<any>) => {
  const drawerWidth = 240;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleProfileMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleCloseProfileMenu = () => {
    setAnchorEl(null);
  };

  return (
    <BaseLayout>
      <Box sx={{ display: "flex" }}>
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            <RouterLink
              to={RoutePaths.HOME}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Typography component="h1" variant="h6" sx={{ marginRight: 17 }}>
                AdStorm
              </Typography>
            </RouterLink>
            <Box sx={{ width: 400 }}>
              <ProjectSelector />
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton
              color="inherit"
              size="large"
              onClick={handleProfileMenu}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseProfileMenu}
            >
              <MenuItem onClick={AuthService.logout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          anchor="left"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <Toolbar />
          <List component="nav" sx={{ pt: 5 }}>
            {Routes.filter((item) => item?.meta?.mainMenu).map(
              (item, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton component={RouterLink} to={item.path}>
                    <ListItemIcon>{item?.meta?.icon}</ListItemIcon>
                    <ListItemText primary={item?.meta?.name} />
                  </ListItemButton>
                </ListItem>
              )
            )}
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 5, height: "100vh" }}>
          <Toolbar />
          {props.children}
        </Box>
      </Box>
    </BaseLayout>
  );
};

export default AppLayout;
