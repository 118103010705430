import { Navigate, useLocation } from "react-router-dom";
import { RoutePaths } from "routes/index";
import AuthService from "../services/auth/AuthService";

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const authenticated = AuthService.isAuthenticated();
  const location = useLocation();

  if (!authenticated)
    return (
      <Navigate to={RoutePaths.LOGIN} state={{ from: location }} replace />
    );
  return children;
};

export default RequireAuth;
