import axios, { AxiosError, AxiosRequestConfig } from "axios";
import AuthService from "../services/auth/AuthService";

const instance = axios.create({
  baseURL: "/api",
});

function handleSuccessResponse(response: AxiosRequestConfig) {
  return response;
}

function handleErrorResponse(error: AxiosError) {
  if (error.response?.status === 401) {
    AuthService.logout();
  }
  return Promise.reject(error);
}

instance.interceptors.response.use(
  (response) => handleSuccessResponse(response),
  (error) => handleErrorResponse(error)
);
export default instance;
