import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentProject,
  selectProjects,
  setCurrentProject,
} from "store/slices/projectsSlice";
import { RootState } from "store";
import { isEmpty } from "lodash";
import { useProjects } from "hooks/projects";

const ProjectSelector = () => {
  const dispatch = useDispatch();
  const currentProject = useSelector((state: RootState) =>
    selectCurrentProject(state)
  );
  const projects = useSelector((state: RootState) => selectProjects(state));

  const handleSelectProject = (projectId: string | number): void => {
    const newProject = projects.filter((project) => project.id === projectId);
    if (newProject) dispatch(setCurrentProject(newProject[0]));
  };

  useProjects();

  if (isEmpty(currentProject)) {
    // don't render menu to prevent out-of-range value in select
    return null;
  }

  return (
    <FormControl fullWidth>
      <Select
        sx={{
          color: "white",
        }}
        variant="standard"
        value={currentProject.id}
        onChange={(e) => handleSelectProject(e.target.value)}
      >
        {projects.map((project) => (
          <MenuItem key={project.id} value={project.id}>
            {`${project.name} (${project.company.name})`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ProjectSelector;
