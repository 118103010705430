import { AuthAPI } from "api/auth";
import Cookies from "js-cookie";
import store from "store";
import { login, logout } from "store/slices/authSlice";
import axios from "api";
import { RoutePaths } from "routes";

export default class AuthService {
  public static async login(email: string, password: string): Promise<void> {
    const response = await AuthAPI.login(email, password);
    Cookies.set("token", response.access_token, { expires: 1 });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.access_token}`;
    store.dispatch(login());
  }

  public static logout(): void {
    Cookies.remove("token");
    delete axios.defaults.headers.common["Authorization"];
    store.dispatch(logout());
    window.location.assign(RoutePaths.LOGIN);
  }

  public static isAuthenticated(): boolean {
    const cookie = Cookies.get("token");
    const state = store.getState();

    if (cookie && state.auth.authenticated) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${cookie}`;
      return true;
    } else if (cookie && !state.auth.authenticated) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${cookie}`;
      store.dispatch(login());
      return true;
    } else {
      delete axios.defaults.headers.common["Authorization"];
      store.dispatch(logout());
      return false;
    }
  }
}
